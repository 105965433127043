import React, { useEffect, useState } from 'react';
import { AppContext, AppInitialProps } from 'next/app';
import ConnectedIntlProvider, { LangContext } from '../src/ConnectedIntlProvider';
import Favicon from '../components/head';

import "../public/styles/stylesGlobalCSS.css"
import { Provider } from 'next-auth/client'
import 'bootstrap/dist/css/bootstrap.min.css';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
config.autoAddCss = false;

import Intercom from "@intercom/messenger-js-sdk";
//Set your APP_ID
var APP_ID = "jlq7hw5m";

const getBrowserLocale = () => {
    try {
        return window.navigator.language.split('-')[0].toLowerCase();
    } catch {
        return 'en';
    }
};

const MyApp = ({ Component, pageProps }: AppContext & AppInitialProps) => {
    const [language, setLanguage] = useState(getBrowserLocale()); // default language depends on browser settings
    useEffect(() => {

        Intercom({
            app_id: APP_ID,
            action_color: '#3D55D9',
            background_color : '#3D55D9',
        })

    }, []);
    return (



            <LangContext.Provider value={{ language, setLanguage }}>
                {/*     <LanguagePicker />*/}
                <ConnectedIntlProvider>
                    <Provider
                        // Provider options are not required but can be useful in situations where
                        // you have a short session maxAge time. Shown here with default values.
                        options={{
                            // Client Max Age controls how often the useSession in the client should
                            // contact the server to sync the session state. Value in seconds.
                            // e.g.
                            // * 0  - Disabled (always use cache value)
                            // * 60 - Sync session state with server if it's older than 60 seconds
                            //  clientMaxAge: 0,
                            // Keep Alive tells windows / tabs that are signed in to keep sending
                            // a keep alive request (which extends the current session expiry) to
                            // prevent sessions in open windows from expiring. Value in seconds.
                            //
                            // Note: If a session has expired when keep alive is triggered, all open
                            // windows / tabs will be updated to reflect the user is signed out.
                            //     keepAlive: 0
                        }}
                        session={pageProps.session} >

                    <Component {...pageProps} />
                        <Favicon />
                    </Provider>
                </ConnectedIntlProvider>
            </LangContext.Provider>
    );
};

export default MyApp;
